/* styles.css (or any appropriate CSS file) */
.container1 {
  display: flex;
}

/* .sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 220px;
  background-color: #f5f5f5;
  overflow-y: auto;
  padding-top: 20px;
} */

.main-content {
  flex: 1;
  padding: 20px;
  overflow-x: auto;
}

table {
  table-layout: auto; 
  border-collapse: collapse;
  width: 100%;
  white-space: nowrap;
  *margin-left: -100px; /*ie7*/
}
td, th {
  vertical-align: top;
  border-collapse: collapse;
  border-top: 1px solid #ccc;
  padding: 10px;
  width: 100px;
}

.outer {
  position: relative;
}
.inner {
  overflow-x: scroll;
  overflow-y: visible;
  width: 1165px; 
  margin-left: 0px;
}

/* .chart-container {
  text-align: center;
} */

.chart-heading {
  margin-top: 0; /* Remove default margin from <h2> */
  font-weight: bold; /* Make the text bold */
}

.wrap-cell {
  /* Set a maximum width for the cell */
  max-width: 200px; /* Adjust this value as needed */

  /* Allow content to wrap within the cell */
  white-space: normal;

  /* Ensure the content is vertically aligned within the cell */
  vertical-align: top;
}

.chart-container {
  width: 600px; /* Adjust the width as needed */
  height: 350px; /* Adjust the height as needed */
  border: 3px solid #000000; /* Add a border with a color of your choice */
  margin: 10px; /* Add margin to space out the charts */
}
